import React, { useState } from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '../../Assets';
export interface InputNumberProp {
  value : number;
  onDecrease: () => void;
  onIncrease: () => void;
  onValueChange: (event) => void;
}
export const InputNumber: React.FC<InputNumberProp> = ({value, onDecrease, onIncrease, onValueChange}) => {
  //
  return (
    <div className="flex items-center space-x-2 text-center">
      <div className=" cursor-pointer " onClick={onDecrease}>
        <MinusCircleIcon
          className={`${value > 0 ? 'fill-black' : 'fill-gray-300'}`}
        />
      </div>
      <input
        type="number"
        value={value}
        onChange={onValueChange}
        autoFocus={false}
        min="0"
        className="p-2 rounded-md border border-7m-bg-gray-100 text-center w-20"
      />
      <div className="cursor-pointer" onClick={onIncrease}>
        <PlusCircleIcon />
      </div>
    </div>
  );
};
