import {
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";

// Utils
import NetworkManager from "../NetworkManager";

// Components
import { TopMenu } from "../Superadmin/TopBar/TopBar";

// Interfaces
import { IUser, ISuperadminAuthContext } from "./Interfaces";

const AuthContext = createContext<ISuperadminAuthContext>(null!);

export const SuperadminAuthProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [user, setUser] = useState<IUser>(null!);
  let currentUser: IUser = user;

  const updateToken = (JWT: string): void => {
    if (user?.JWT && JWT !== user?.JWT) {
      currentUser.JWT = JWT;
      setUser({ ...user, JWT });
      window.localStorage.setItem("JWT", JWT);
    }
  };

  const getToken = (): string =>
    currentUser?.JWT || window.localStorage.getItem("JWT") || "";

  const updateUser = (user: IUser): void => {
    setUser(user);
    currentUser = user;

    window.localStorage.setItem("id", user._id);
    window.localStorage.setItem("user", user.username);
    window.localStorage.setItem("displayName", user.displayName);
    window.localStorage.setItem("profile", JSON.stringify(user.profiles));
    window.localStorage.setItem("JWT", user.JWT);
  };

  const logoutUser = (): void => {
    setUser(null!);

    window.localStorage.removeItem("id");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("profile");
    window.localStorage.removeItem("JWT");
  };
  const getAccountId = (): string =>
    window.localStorage.getItem("accountId") || "";

  /* TODO : manage superadmin auth */
  const networkManager = new NetworkManager(
    getToken,
    logoutUser,
    updateToken,
    getAccountId
  );


  const value = {
    user,
    updateToken,
    getToken,
    getAccountId,
    updateUser,
    logoutUser,
    networkManager,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const SuperadminRequireAuth = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, []);

  return (
    <Popover>
      <TopMenu />

      <div className="flex p-3 bg-gray-100 h-full">
        <Outlet />
      </div>
    </Popover>
  );
};
