import React, { useEffect, useState } from "react";

// Icons
import { TickIcon } from "../../Assets/index";
import { DEFAULT_LANGUAGE, LANGUAGE_CUSTOMER_KEY } from "../../constants";
import { LanguageSetting } from "../../Interfaces/generalInterfaces";
// Types
import { languagesDictionary } from "../../libs/interfaces/languages/languages.interface";
import { getFlag } from "../../Utils/utils";
export interface ILangContent {
  closeModal: () => void;
  langList: LanguageSetting[];
}
export interface ILang {
  name: LanguageSetting;
  selected: boolean;
}

export const LangContent: React.FC<ILangContent> = ({
  langList,
  closeModal,
}) => {
  const [newlist, setNewList] = useState<ILang[]>([]);
  const getDefaultLanguage = (): LanguageSetting => {
    if (window.localStorage.getItem(LANGUAGE_CUSTOMER_KEY)) {
      return window.localStorage.getItem(
        LANGUAGE_CUSTOMER_KEY
      ) as LanguageSetting;
    }
    return DEFAULT_LANGUAGE;
  };
  const handleClick = (lang: LanguageSetting) => {
    window.localStorage.setItem(LANGUAGE_CUSTOMER_KEY, lang);
    const list = getNewList(langList);
    list.forEach((v) => {
      v.selected = v.name === lang;
    });
    setNewList(list);
  };
  const getNewList = (langList: LanguageSetting[]): Array<ILang> => {
    const list: Array<ILang> = [];
    langList.forEach((v) => {
      list.push({
        name: v,
        selected: v === getDefaultLanguage(),
      });
    });
    return list;
  };
  useEffect(() => {
    setNewList(getNewList(langList));
  }, []);
  return (
    <React.Fragment>
      <ul className="py-4 px-4"> 
        {newlist.map((item) => (
          <li
            className="p-3 flex space-x-2 justify-between cursor-pointer border-t border-sky-100"
            onClick={() => handleClick(item.name)}
          >
            <div className="flex">
              {getFlag(item.name)}
              {languagesDictionary[item.name].description}
            </div>
            {item.selected && <TickIcon className="w-5"></TickIcon>}
          </li>
        ))}
      </ul>

      {/* <div className="mt-2 text-orange-500 flex justify-center text-center items-center content-center">
        <button
          type="button"
          className="mt-3 mb-1 inline-flex font-semibold text-xl px-4 py-2 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={closeModal}
        >
          
          Close
        </button>
      </div> */}
    </React.Fragment>
  );
};
