import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IBottomModal {
  title?: string;
  show: boolean;
  children: React.ReactNode;
  closeModal: () => void;
}

export const BottomModal: React.FC<IBottomModal> = ({
  title,
  closeModal,
  show,
  children,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <React.Fragment>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-full text-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0 bg-opacity-75 transition-opacity"
                style={{ backgroundColor: 'rgba(18, 5, 66, 0.8)' }}
                onClick={closeModal}
              />
            </Transition.Child>

            <span className="hidden align-middle h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-12 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-12 translate-y-0 scale-95"
            >
              <div className="inline-block h-full w-full rounded-t-3xl text-left overflow-hidden shadow-xl transform transition-all my-0">
                <div className="inline-block h-full w-full mt-5 -mb-2 bg-white rounded-t-2xl text-left overflow-hidden shadow-xl transform transition-all my-8">
                  {title && (
                    <div className="bg-white px-4 pt-5 p-6 pb-4">
                      <div className="flex px-4">
                        <div className="text-left">
                          <Dialog.Title
                            as="span"
                            className="text-xl font-bold"
                          >
                            {title}
                          </Dialog.Title>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="bg-gray-50  flex-row-reverse">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};
