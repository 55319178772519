import React, { Fragment } from 'react';
import { FcMenu } from 'react-icons/fc';
import { MdClose } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

// Assets
import ReactLogo from '../../Assets/sooner-logo.svg';

// Hooks
import { useAuth } from '../../Auth/SuperadminAuth';

export const TopMenu: React.FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const getClass = (isActive: boolean) => {
        const baseClass = `text-base font-medium hover:text-orange-700 ${isActive ? "text-orange-500" : "text-gray-500"}`;

        return baseClass;
    };

    const logout = () => {
        auth.logoutUser();
        navigate('/superadmin/login');
    };

    return (
        <Fragment>
            <div className="mx-auto px-4 sm:px-6 ">
                <div className="flex justify-between items-center border-gray-100 py-3 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <NavLink to="/home">
                            <img
                                className="h-12 w-auto sm:h-10 lg:h-16"
                                src={ReactLogo}
                                alt=""
                            />
                        </NavLink>

                        <div className='flex pl-4 items-center place-content-center'>
                            <p className='text-2xl font-medium text-gray-500'>Superadmin</p>
                        </div>
                    </div>

                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <FcMenu className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>

                    <nav className="hidden md:flex space-x-10">
                        <React.Fragment>
                            <NavLink to="/superadmin/home" className={({ isActive }) => getClass(isActive)}>
                                Home
                            </NavLink>
                            <NavLink to="/superadmin/restaurants" className={({ isActive }) => getClass(isActive)}>
                                Restaurants
                            </NavLink>
                        </React.Fragment>
                    </nav>

                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        <button onClick={e => logout()} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-orange-700">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={ReactLogo}
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <MdClose className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6">
                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                <React.Fragment>
                                    <Popover.Button> 
                                        <NavLink to="/superadmin/home" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                            Home
                                        </NavLink>
                                    </Popover.Button>
                                    <Popover.Button>
                                        <NavLink to="/superadmin/restaurants" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                            Restaurants
                                        </NavLink>
                                    </Popover.Button>
                                </React.Fragment>
                            </div>
                            <div>
                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    <button className="text-indigo-600 hover:text-indigo-500" onClick={e => logout()}>
                                        Logout
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Fragment >
    );
};