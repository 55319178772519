import React, { useState } from 'react';
import {
  EMessageType,
  IBuzz,
  LanguageSetting,
} from '../../Interfaces/generalInterfaces';
import { Link, useNavigate } from 'react-router-dom';
import { FastfoodIcon, MenuIcon3 } from '../../Assets';
export interface IBuzzContent {
  closeModal: () => void;
  messageTrigger: (message: string, messageType: EMessageType) => void;
  language: LanguageSetting;
  restaurantId: string;
  chatURL: string;
  menuURL: string;
  buzz: Array<IBuzz>;
}

export const BuzzContent: React.FC<IBuzzContent> = ({
  closeModal,
  messageTrigger,
  language,
  chatURL,
  menuURL,
  restaurantId,
  buzz,
}) => {
  const navigate = useNavigate();
  const customMessage = (messageContent: string) => {
    try {
      messageTrigger(messageContent, EMessageType.BUZZ);
      navigate(chatURL);
    } catch (error) {
      console.log(error);
    }
    closeModal();
  };

  return (
    <React.Fragment>
      <div className="bg-7m-bg-gray-primary px-5 py-2 w-auto">
        <Link to={menuURL}>
          <div className="flex align-middle items-center space-x-8 bg-white text-black px-4  border border-7m-gray-100 rounded-xl outline outline-4 outline-7m-pink-100  my-5 cursor-pointer focus:bg-7m-pink-100 ">
            <MenuIcon3 className=" w-14" />
            <div className="text-left">
              <h1 className="font-bold text-xl">Check our menu</h1>
              <p className=" text-xs ">To place your order now</p>
            </div>
          </div>
        </Link>

        <div className="grid grid-cols-3 gap-1 relative ">
          {buzz &&
            buzz.map((item, index) => (
              <>
                <div
                  key={index}
                  className=" relative rounded-xl bg-white text-black border align-middle text-center p-2 cursor-pointer hover:bg-7m-bg-main hover:text-white"
                  onClick={() => customMessage(item[language])}
                >
                  <div className="rounded-[50%]  p-2 inline-block border-2 border-7m-pink-50 bg-7m-bg-main">
                    <img
                      className="svg-white w-6"
                      src={`/icons/${item.icon}`}
                      alt={item.icon}
                    />
                  </div>
                  <p className="text-xs ">{item[language]}</p>
                </div>
              </>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};
