export const languages = ["en", "es", "fr", "km", "zh-CN"] as const;
export type Language = typeof languages[number];
export const languagesDictionary: Record<
  Language,
  { code: string; description: string; flag?: string; active: boolean }
> = {
  en: {
    code: "en",
    description: "English",
    flag: "UnitedStatesFlagCircle",
    active: true,
  },
  km: {
    code: "km",
    description: "Khmer",
    flag: "KH",
    active: true,
  },
  "zh-CN": {
    code: "zh",
    description: "Chinese",
    flag: "CN",
    active: true,
  },
  es: {
    code: "es",
    description: "Spanish",
    flag: "SpainFlagCircle",
    active: false,
  },
  fr: {
    code: "fr",
    description: "French",
    flag: "FranceFlagCircle",
    active: false,
  },
};
