import React, { useState } from "react";

const getItem = (key: string) => (
    document.cookie.split("; ").reduce((total, currentCookie) => {
      const item = currentCookie.split("=");
      const storedKey = item[0];
      const storedValue = item[1];
  
      return key === storedKey ? decodeURIComponent(storedValue) : total;
    }, "")
);

const setItem = (key: string, value: string, minutes: number) => {
  const now = new Date();
  now.setTime(now.getTime() + Number(minutes) * 60000);

  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
};

export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: string, minutes: number) => {
    setCookie(value);
    setItem(key, value, minutes);
  };

  return [cookie, updateCookie] as const;
};