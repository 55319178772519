import React, { useEffect, useState } from 'react';
import { ICategory } from '../../Interfaces/generalInterfaces';
export interface CategoryProp {
  categories: Array<ICategory>;
  onfilter: (category: ICategory) => void;
}
export const Categories: React.FC<CategoryProp> = ({
  categories,
  onfilter,
}) => {
  const [activeCategory, setActiveCategory] = useState<number>(-1);
  const onFilterCategory = (category: ICategory) => {
    setActiveCategory(category.id);
    onfilter(category);
  };

  const newCategory = (): Array<ICategory> => {
    const all = [
      {
        id: -1,
        name: 'All',
      },
      {
        id: 0,
        name: 'Popular',
      },
    ];
    let newCat: Array<ICategory> = [];
    if (categories) {
      newCat = [...all, ...categories];
    } else {
      newCat = all;
    }
    return newCat;
  };

  return (
    <div className="sticky bg-white w-full top-16 z-20 pl-5 py-5 flex space-x-2 overflow-x-auto whitespace-nowrap">
      {newCategory() &&
        newCategory().map((item, key) => (
          <div
            key={item.id}
            onClick={() => onFilterCategory(item)}
            className={`${
              activeCategory === item.id
                ? 'bg-7m-bg-main text-white'
                : 'bg-7m-pink-10 text-black'
            } px-4 py-2 rounded-xl font-bold`}
          >
            {item.name}
          </div>
        ))}
    </div>
  );
};
