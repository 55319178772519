import React, { useState } from 'react';
import Select from 'react-select';
import { Modal } from '../../libs/components/Modal/modal';

export interface INewMenu {
  setMenu: (
    menuName: string,
    MenuURL?: string,
    type?: string,
    selectedFile?: File
  ) => Promise<boolean>;
}
export enum ETypeOfMenu {
  Website = 'website',
  PDF = 'pdf',
}
export const NewMenu: React.FC<INewMenu> = ({ setMenu }) => {
  const [menuName, setMenuName] = useState<string>('');
  const [menuURL, setMenuURL] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState<File>();
  const [isModal, setModal] = useState(false);
  const typeOptions = [
    {
      value: 'website',
      label: 'Website',
    },
    {
      value: 'pdf',
      label: 'PDF',
    },
  ];

  const [type, setType] = useState<string>(ETypeOfMenu.Website);

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSave = async () => {
    const response = await setMenu(menuName, menuURL, type, selectedFile);
    if (response) {
      setMenuName('');
      setMenuURL('');
      setModal(false);
    }
  };

  const onTypeChange = (v: any) => {
    setType(v.value);
  };

  return (
    <>
      <div className="flex mb-2 align-middle justify-end">
        <button
          onClick={(e) => {
            setModal(true);
          }}
          className="float right-0 rounded-md border 
                            border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base text-white hover:bg-orange-700 
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm mb-2"
        >
          <span> Add New </span>
        </button>
      </div>
      <Modal
        title="Menu"
        onSave={() => {
          handleSave();
        }}
        closeModal={() => {
          setModal(false);
        }}
        isShowing={isModal}
      >
        <form className="mt-4" name="frmConnector">
          <label className="text-gray-500 text-sm">Name</label>
          <input
            type="text"
            onChange={(e) => setMenuName(e.target.value)}
            required
            className="rounded-lg flex-1 px-2 mr-4 py-1 border text-gray-800 border-gray-200 bg-white h-10 w-full mb-2"
            placeholder="Food"
          />
          <label className="text-gray-500 text-sm">Type</label>
          <Select
            className="rounded-lg  w-full text-sm text-left py-1 text-gray-800 border-gray-200 bg-white h-10 mb-2 "
            options={typeOptions}
            defaultValue={typeOptions[0]}
            onChange={(v) => onTypeChange(v)}
          />

          {type === ETypeOfMenu.Website ? (
            <>
              <label className="text-gray-500 text-sm">Website URL</label>
              <input
                type="text"
                autoComplete="false"
                onChange={(e) => setMenuURL(e.target.value)}
                required
                className="rounded-lg flex-1 px-2 mr-4 py-1 border text-gray-800 border-gray-200 bg-white h-10 w-full mb-2"
                placeholder="https://example.com"
              />
            </>
          ) : (
            <>
              <input
                className="mt-2"
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                accept="application/pdf"
              />
              <div>{file && `${file.name} - ${file.type}`}</div>
            </>
          )}
        </form>
      </Modal>
    </>
  );
};
