import React from 'react';
import { CampfireIcon } from '../../Assets';
import { IPopularProduct, IProduct } from '../../Interfaces/generalInterfaces';
import { formatPrice } from '../../utils';
export interface Prop {
  products: Array<IPopularProduct>;
  onSelectedItem?: (item: IProduct) => void;

}
export const Popular: React.FC<Prop> = ({ products , onSelectedItem}) => {
   

  return (
    <div>
      <div className="flex mx-5 mb-5 items-center">
        <CampfireIcon className="w-10 h-10" />
        <h1 className="text-2xl font-bold">Popular</h1>
      </div>
      <div className="bg-7m-bg-gray-primary p-5">
        <div className="grid grid-cols-2 gap-4">
          {products && products.map((v, k) => (
            <div key={k} onClick={() => onSelectedItem(v.products)} className="text-left">
              <img
                className="w-full shadow-md shadow-slate-400"
                src={v.products.image_url}
                alt="food1"
              ></img>
              <h3 className="font-bold mt-2">{v.products.name}</h3>
              <div className="px-4 py-1 mt-2 text-xs w-fit rounded-2xl bg-7m-bg-main text-white font-bold">
                {formatPrice(v.products.price)}
              </div>
            </div>
          ))}

          {/* <div className="text-left">
            <img
              className="w-full shadow-md shadow-slate-400"
              src={'/images/food-2.png'}
              alt="food2"
            ></img>
             <h3 className="font-bold mt-2">Lorem ipsum</h3>
            <div className="px-4 py-1 mt-2 text-xs w-fit rounded-2xl bg-7m-bg-main text-white font-bold">
                $9.00
            </div>
          </div>
          <div className="text-left">
            <img
              className="w-full shadow-md shadow-slate-400"
              src={'/images/food-3.png'}
              alt="food3"
            ></img>
             <h3 className="font-bold mt-2">Lorem ipsum</h3>
            <div className="px-4 py-1 mt-2 text-xs w-fit rounded-2xl bg-7m-bg-main text-white font-bold">
                $9.00
            </div>
          </div>
          <div className="text-left">
            <img
              className="w-full shadow-md shadow-slate-400"
              src={'/images/food-4.png'}
              alt="food4"
            ></img>
             <h3 className="font-bold mt-2">Lorem ipsum</h3>
            <div className="px-4 py-1 mt-2 text-xs w-fit rounded-2xl bg-7m-bg-main text-white font-bold">
                $9.00
            </div>
          </div>
          <div className="text-left">
            <img
              className="w-full shadow-md shadow-slate-400"
              src={'/images/food-4.png'}
              alt="food4"
            ></img>
             <h3 className="font-bold mt-2">Lorem ipsum</h3>
            <div className="px-4 py-1 mt-2 text-xs w-fit rounded-2xl bg-7m-bg-main text-white font-bold">
                $9.00
            </div>
          </div>
          <div className="text-left">
            <img
              className="w-full shadow-md shadow-slate-400"
              src={'/images/food-4.png'}
              alt="food4"
            ></img>
             <h3 className="font-bold mt-2">Lorem ipsum</h3>
            <div className="px-4 py-1 mt-2 text-xs w-fit rounded-2xl bg-7m-bg-main text-white font-bold">
                $9.00
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
