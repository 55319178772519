import React from 'react';
import { IBanner } from '../../Interfaces/generalInterfaces';
export interface Prop {
  banners: Array<IBanner>;
}
export const Promotions: React.FC<Prop> = ({ banners }) => {
  return (
    <div className="px-5 py-4 text-left text-black ">
      <div className="grid grid-cols-2 gap-4"></div>
      <h2 className="text-xl font-bold">Special Promotion</h2>
      {banners &&
        banners.map((v, k) => (
          <>
            <div className=" mt-4 ">
              <img
                className="w-full"
                src={v.image}
                alt="sample"
              ></img>
              <h3 className="text-md font-bold mt-2">{v.title}</h3>
              <p>{v.text}</p>
            </div>
          </>
        ))}
    </div>
  );
};
