import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChatBubbleIcon } from '../../Assets';
import { Badge, Button, Fab } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import SmsIcon from '@mui/icons-material/Sms';
interface IMessageBottom {
  chatURL: string;
  badgeContent: number;
}
export const MessageBottom: React.FC<IMessageBottom> = ({
  chatURL,
  badgeContent,
}) => {
  return (
    <Link to={chatURL}>
      <div className="sticky w-full bottom-0 px-2 py-2 z-10 ">
        <Fab  className="w-full" variant="extended">
        <Badge color="error" badgeContent={badgeContent}>
              
        </Badge>
          <SmsIcon sx={{ mr: 1 }} />
          Send a message
        </Fab>
        {/* <div className="bg-white rounded-2xl border border-7m-gray-100 p-4 flex space-x-4 align-middle items-center">
          <div>
            <Badge color="error" badgeContent={badgeContent}>
              <ChatBubbleIcon className="w-18 h-18" />
            </Badge>
          </div>
          <div className="text-left">
            <h1 className=" font-bold text-xl">Send us a message</h1>
            <p className="text-xs">Feel free to send us any messages</p>
          </div>
        </div> */}
      </div>
    </Link>
  );
};
