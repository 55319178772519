import { CN, KH, US } from "country-flag-icons/react/3x2";
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_CUSTOMER_KEY,
  LANGUAGE_ORG_KEY,
} from "../constants";
import { LanguageSetting } from "../Interfaces/generalInterfaces";
export const plainPersistentMessages = (events: any) => {
  const messages = events.reduce((acc: any, element: any) => {
    const message = element[0].data;

    if (message) {
      acc.push(message);
    }

    return acc;
  }, []);

  return messages;
};

export const decodeToken = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getFlag = (lang: any) => {
  if (lang === "zh-CN") {
    return <CN className="w-5 mr-2"></CN>;
  } else if (lang === "en") {
    return <US className="w-5 mr-2"></US>;
  } else if (lang === "km") {
    return <KH className="w-5 mr-2"></KH>;
  }
};
export const getOrgDefaultLanguage = (): LanguageSetting => {
  if (window.localStorage.getItem(LANGUAGE_ORG_KEY)) {
    return window.localStorage.getItem(LANGUAGE_ORG_KEY) as LanguageSetting;
  }
  if (window.localStorage.getItem(LANGUAGE_ORG_KEY) === 'cn') {
    return 'zh-CN';
  }
  return DEFAULT_LANGUAGE;
};
export const getCustomerDefaultLanguage = (): LanguageSetting => {
  if (window.localStorage.getItem(LANGUAGE_CUSTOMER_KEY)) {
    return window.localStorage.getItem(
      LANGUAGE_CUSTOMER_KEY
    ) as LanguageSetting;
  }
  return DEFAULT_LANGUAGE;
};

 