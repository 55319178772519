import { LanguageSetting } from "./Interfaces/generalInterfaces";

export const EVENT_MESSAGE_SENT: string = "client-message-sent";
export const EVENT_CLEAR_TABLE: string = "client-clear-table";
export const EVENT_MESSAGE_REACTION: string = "client-message-reaction";
export const DEFAULT_BANNED_MINUTES: number = 10;
export const DEFAULT_TERMS_ACCEPTANCE_MINUTES: number = 1440;

// Restaurant form view
export const messageMaxLength = 90;
export const defaultServerConfirmationMessage = "Ok";
export const moreThanNineMessagesCounterIcon = "!";
export const defaultMaxShownTables = 4;
export const smallViewportMaxShownTables = 8;
export const mediumViewportMaxShownTables = 10;
export const largeViewportMaxShownTables = 14;
export const extraLargeViewportMaxShownTables = 18;

// GLobal
export const maxTableTitleLength = 5;
export const LANGUAGE_CUSTOMER_KEY:string = "Language";
export const LANGUAGE_ORG_KEY:string = "OrgLanguage";

export const DEFAULT_LANGUAGE:LanguageSetting = "en";
