import React from "react";
import QRCode from "qrcode.react";

export interface IQRContent {
  closeModal: () => void;
  url: string;
  tableNumber?: string;
}

export const QRContent: React.FC<IQRContent> = ({
  closeModal,
  url,
  tableNumber,
}) => {
  const print = () => {
    const canvas = document.getElementById("QRCode") as any;
    const QRBuffer = canvas?.toDataURL("image/jpg", 0.3);

    const newPopup = window.open("", "", "height=500, width=500");

    // write all this in just one
    newPopup?.document.write("<html>");
    newPopup?.document.write(`<img src=${QRBuffer} />`);
    newPopup?.document.write(`<br/>`);
    if (tableNumber) {
      newPopup?.document.write(
        `<span style="margin-left:40px; margin-top:40px;">${tableNumber}</span>`,
      );
    }
    newPopup?.document.write("</body></html>");
    newPopup?.document.close();

    setTimeout(() => newPopup?.print(), 500);
  };

  return (
    <React.Fragment>
      <div className="text-orange-500 flex justify-center text-center items-center content-center mt-3">
        <a type="button" className="border border-black p-5 w-auto" href={"#"}>
          <QRCode id="QRCode" value={url} className="w-36 h-36" />
        </a>
      </div>

      <div className="mb-5 mt-2 text-orange-500 flex justify-center text-center items-center content-center">
        {tableNumber && (
          <button
            type="button"
            className="mt-3 mb-1 inline-flex font-semibold text-xl px-6 py-2 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => print()}
          >
            Print
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
